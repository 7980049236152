<template>
  <div>
    <p>第三方登录</p>
    <vue-telegram-login mode="callback" telegram-login="INIAirdropBot" @callback="yourCallbackFunction" />
  </div>
</template>
<script>
import vueTelegramLogin from './components/vue-telegram-login.vue';

export default {
  components: { vueTelegramLogin },
  data() {
    return {};
  },
  created() { },
  methods: {
    yourCallbackFunction(user) {
      console.log(user);
      //正常回调拿到的user信息
      //{
      //	auth_date: 1646705584, first_name: "zzx",…}
      //	auth_date: 1646705584
      //	first_name: "zzx"
      //	hash: "9118f5e1c53d7cf3308c696565d26efc96f90d0fb59255b82bc39fd4636ddc50"
      //	id: 5021490450
      //	last_name: "fys"
      //	photo_url: "https://t.me/i/userpic/320/jvyC1v4qH7KrQqUBr5T0dK04wNm_Vr_Acl9WMynKMiuG-ToB9_E01-WCLJn8flUB.jpg"}
      //}
      //	这里需要写你业务的逻辑需求
    }
  }
}
</script>
